//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import sectionTitleComponent from './sectionTitleComponent';

export default {
    name: 'About',

    components: {
        sectionTitleComponent
    },

    data() {
        return {
            aboutTitle: this.$t('aboutTitle')
        }
    }
}
